import { Box, Button, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import style from '../Styles/paymentFailed.module.css'
import { formatUnixTimeStamp } from "../Common/common";
import { useNavigate } from "react-router-dom";

interface PaymentFailedInterface {
    orderId: string | undefined;
    amount: string | undefined;
    timeStamp: string | undefined
}

export const PaymentFailed: React.FC<PaymentFailedInterface> = ({ amount, timeStamp, orderId }) => {
    const navigate = useNavigate()
    const [isMobile] = useMediaQuery("(max-width:480px)");

    const handleRedirection = () => {
        return navigate('/planDetails')
    }

    return (
        <Box className={style.main}>
            <Box className={style.child}>
                <Flex flexDir={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Image className={style.img} src="/Images/paymentFailed.png" />
                    <Text className={style.failedText}>Payment Failed</Text>
                </Flex>
                <Flex className={style.paymentDetails}>
                    <Box>
                        <Text fontSize={isMobile ? "10px" : "14px"}>Order ID :</Text>
                        {amount && <Text fontSize={isMobile ? "10px" : "14px"}>Amount :</Text>}
                        <Text fontSize={isMobile ? "10px" : "14px"}>Timestamp :</Text>
                    </Box>
                    <Box>
                        <Text fontSize={isMobile ? "10px" : "14px"}>{orderId || "Null"}</Text>
                        {amount && <Text fontSize={isMobile ? "10px" : "14px"}>{`Rs. ${Number(amount) / 100}/-`}</Text>}
                        <Text fontSize={isMobile ? "10px" : "14px"}>{formatUnixTimeStamp(Number(timeStamp))}</Text>
                    </Box>
                </Flex>
                <Button className={style.btn} colorScheme="cyan" onClick={handleRedirection}>
                    <Text className={style.btnTxt} >
                        Try Again
                    </Text>
                </Button>
            </Box>
        </Box>
    )
}