import {
  Box,
} from "@chakra-ui/react";
import Registration from "./B2CMicroComponent/Registration";
import VerifyOtp from "./B2CMicroComponent/VerifyOtp";
import { useState } from "react";
import PlanDetails from "../B2CPlans/PlanDetails";

const RegistrationProcess = () => {
  const [step, setStep] = useState("REGISTRATION");

  return (
    <Box>
      {step === "REGISTRATION" && <Registration setStep={setStep}/>}
      {step === "VERIFY_OTP" && <VerifyOtp setStep={setStep}/>}
      {step === "SUBSCRIBE_PLAN" && <PlanDetails setStep={setStep}/>}
    </Box>
  );
};

export default RegistrationProcess;
