import { useEffect, useState } from 'react';
import Navbar from '../../GlobalComponents/Navbar/Navbar';
import { Order } from './Component/Order';
import { getPocoOrdersBySubscriberId } from './Actions/actions';
import { useSelector } from 'react-redux';
import styles from './Orders.module.css'; 
import { Box } from '@chakra-ui/react';
import { PocoOrderType } from './Types/types';

export const Orders = () => {
    const [orders, setOrders] = useState({
        ordersLoading: false,
        ordersLoaded: false,
        orders: []
    });

    const subscriberId = useSelector((state: any) => state.subscriber.subscriberId);
    console.log("subscriberId", subscriberId);
    useEffect(() => {
        // fetch orders
        setOrders({
            ordersLoading: true,
            ordersLoaded: false,
            orders: []
        });

        // fetch orders from Poco API and update the state of orders array with the fetched data
        const fetchPocoOrders = async () => {
            const res = await getPocoOrdersBySubscriberId(subscriberId)
            
            setOrders({
                ordersLoading: false,
                ordersLoaded: true,
                orders: res.data.filter((order: PocoOrderType) => order.paymentStatus === "paid" || order.paymentStatus === "failed") 
            });
        }
        fetchPocoOrders();
    }, [subscriberId]);
    console.log("orders", orders);
    return (
        <>
            <Navbar />
            <div className={styles.container}> 
                <div className={styles.headingBox}>
                    <h2 className={styles.heading}>Orders</h2>
                </div>
                <Box className={styles['orders-container']}>
                    {
                        orders.ordersLoading && <h3 style={{ textAlign: 'center' }}>Loading Your orders...</h3>
                    } 
                    {
                        orders.ordersLoaded &&
                        orders.orders.length > 0 &&
                        orders.orders.map((order: PocoOrderType) => {
                                    return <Order key={order._id} pocoOrder={order} />
                                })
                    }
                    {
                        orders.ordersLoaded && orders.orders.length === 0 && <h3 style={{ textAlign: 'center' }}>No orders found.</h3>
                    }
                </Box>
            </div>
        </>
    )
}
