import { CSSProperties } from 'react';

export const PlanStyle = {
  chooseNobenStyle: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.64px",
    color: "#000",
    fontFamily: "Poppins, sans-serif",
    fontVariantNumeric: "lining-nums tabular-nums",
    fontFeatureSettings: "'liga' off, 'clig' off",
  } as CSSProperties,
  noOfBenInnerStyle: {
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    backgroundColor: "#F1F1FC",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  } as CSSProperties,
  headingStyle: {
    color: "#000",
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.64px",
  } as CSSProperties,
  overlayBoxStyle: {
    top:0,
    left: 0,
    position: "absolute", 
    opacity: 0.5, 
    transform: "rotate(-56.78deg)",
    background: "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%)",
    zIndex: 100,

    // Elliptical effect
    borderRadius: "50% / 25%", // Elliptical border radius
  } as CSSProperties
};
