import { getPocoOrders } from "../../../http";

// get pocoOrders from poco orders collection
export const getPocoOrdersBySubscriberId = async (subscriberId: string) => {
    try {
        const response = await getPocoOrders(subscriberId);
        return response.data;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
}