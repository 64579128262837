import { addBeneficiaryAddress, addBeneficiaryEmergencyContact, addBeneficiaryInsurance, createHospital, deleteBeneficiaryAddress, deleteBeneficiaryEmergencyContact, deleteBeneficiaryInsurance, getAllBeneficiariesData, getAzureSignedUrl, getMedicalInfo, getNearByHospital, getSingleBenData, saveMedicalInfo, updateBeneficiaryAddress, updateBeneficiaryEmergencyContact, updateBeneficiaryInsurance, updateBeneficiaryPersonalInfo } from "../../../http";
import axios from "axios";
import { base64StringToBlob } from "blob-util";

const getSingleBeneficiaryData = async (id: string) => {
  let beneficiaryData;
  try {
    beneficiaryData = await getSingleBenData(id);
    return beneficiaryData.data.data;
  } catch (error) {
    console.log("error in beneficiary data action", error)
    throw error;
  }
}

const getSignedUrl = async (docName: string, benId: string, docType: string) => {
  try {
    const signedUrl = await getAzureSignedUrl(docName, benId, docType);
    return signedUrl.data.data;
  } catch (error) {
    throw error;
  }
}

const uploadFileToAzure = async (signedUrl: string, files: any) => {
  const contentType = files.fileList[0].type;
  const b64Data = files.base64.split(",")[1];
  const blob = base64StringToBlob(b64Data, contentType);
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: signedUrl,
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "x-ms-date": new Date().toUTCString(),
        ContentEncoding: "base64",
        "Content-Type": contentType,
      },
      data: blob,
    };

    const response = await axios.request(config);
    console.log(response);
    return signedUrl.split("?")[0];
  } catch (error) {
    throw error;
  }
}

const updatePersonalInfo = async (benId: string, payload: any): Promise<void> => {
  try {
    let response = await updateBeneficiaryPersonalInfo(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const uploadFile = async (beneficiaryId: string, files: any, fileType: string) => {
  try {
    let signedUrlResponse = await getSignedUrl(files.fileList[0].name, beneficiaryId, fileType);
    let signedUrl = signedUrlResponse.data.signedUrl;
    let uploadToAzureResponse = await uploadFileToAzure(signedUrl, files);
    return uploadToAzureResponse;
  } catch (error) {
    throw error;
  }
}

const deleteAddressAction = async (benId: string, addressId: string) => {
  try {
    let response = await deleteBeneficiaryAddress(benId, addressId);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const addAddressAction = async (benId: string, payload: any) => {
  try {
    let response = await addBeneficiaryAddress(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updateAddressAction = async (benId: string, payload: any) => {
  try {
    let response = await updateBeneficiaryAddress(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const addEmergencyContact = async (benId: string, payload: any) => {
  try {
    let response = await addBeneficiaryEmergencyContact(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const deleteEmergencyContact = async (benId: string, payload: any) => {
  try {
    let response = await deleteBeneficiaryEmergencyContact(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updateEmergencyContact = async (benId: string, payload: any) => {
  try {
    let response = await updateBeneficiaryEmergencyContact(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const addBenInsurance = async (benId: string, payload: any) => {
  try {
    let response = await addBeneficiaryInsurance(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const deleteBenInsurance = async (benId: string, policyId: string) => {
  try {
    let response = await deleteBeneficiaryInsurance(benId, policyId);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updateBenInsurance = async (benId: string, payload: any) => {
  try {
    let response = await updateBeneficiaryInsurance(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  let month = now.getMonth() + 1; // Months are 0-indexed
  let day = now.getDate();

  // Add leading zeros to single-digit months and days
  if (month < 10) {
    // @ts-ignore
    month = `0${month}`;
  }
  if (day < 10) {
    // @ts-ignore
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
}

const getNearByHospitals = async (payload: any) => {
  try {
    let response = await getNearByHospital(payload);
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadius = 6371;

  const radLat1 = (lat1 * Math.PI) / 180;
  const radLon1 = (lon1 * Math.PI) / 180;
  const radLat2 = (lat2 * Math.PI) / 180;
  const radLon2 = (lon2 * Math.PI) / 180;

  const dLat = radLat2 - radLat1;
  const dLon = radLon2 - radLon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(radLat1) * Math.cos(radLat2) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = earthRadius * c;

  return distance;
}

const createHospitalData = async (payload: any) => {
  try {
    let response = await createHospital(payload);
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

const createMedicalInfo = async (benId: string, payload: any) => {
  try {
    let response = await saveMedicalInfo(benId, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const getMedicalInfoAction = async (benId: string) => {
  try {
    let response = await getMedicalInfo(benId);
    console.log("response in medical info action", response.data[0]);
    return response.data[0];
  } catch (error) {
    throw error;
  }
}

const getAllAddresses = async (subId: string) => {
  try {
    let data = await getAllBeneficiariesData(subId);
    let subData = data.data.data;

    let addresses: any = [];

    for (let i = 0; i < subData.length; i++) {
      if (subData[i]?.address) {
        for (let j = 0; j < subData[i].address.length; j++) {
          if (subData[i]?.address[j]?.label) {
            addresses.push(subData[i].address[j]);
          }
        }
      }
    }

    // return unique addresses using addressLine1
    addresses = addresses.reduce((unique: any, address: any) => {
      if (!unique.find((item: any) => item.addressLine1 === address.addressLine1)) {
        unique.push(address);
      }
      return unique;
    }, []);

    return addresses;
  } catch (error) {
    throw error;
  }
}


export {
  getSingleBeneficiaryData,
  getSignedUrl,
  uploadFileToAzure,
  updatePersonalInfo,
  uploadFile,
  deleteAddressAction,
  addAddressAction,
  updateAddressAction,
  addEmergencyContact,
  deleteEmergencyContact,
  updateEmergencyContact,
  addBenInsurance,
  deleteBenInsurance,
  updateBenInsurance,
  getCurrentDate,
  getNearByHospitals,
  getDistanceFromLatLonInKm,
  createHospitalData,
  createMedicalInfo,
  getMedicalInfoAction,
  getAllAddresses
}
