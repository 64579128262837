import axios from "axios"
import apiConfig from "../../apiConfig/apiConfig";
const baseUri = apiConfig.api.baseURL || "https://v3.apis.pococare.com";
// const testUri="http://localhost:3009/api/v1/order/verify"
export const paymentValidation=async(payload)=>{
    try{
        const res=await axios.post(`${baseUri}/payment/api/v1/order/verify`,payload);
        // const res=await axios.post(`${testUri}`,payload);
        return res
    }catch(e){
        throw e;
    }
}