import React from "react";

import { Box } from "@chakra-ui/react";
import Navbar from "../../../GlobalComponents/Navbar/Navbar";
import Footer from "../../../GlobalComponents/Footer/Footer";
// import FixedButton from "../FixedButton/FixedButton";
interface layoutProps {
  children: any;
  companyName: string;
}
const Layout: React.FC<layoutProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Box marginTop="2rem">
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
