import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    registerB2CUser: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const { registerB2CUser } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
