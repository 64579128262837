import React, { useEffect, useRef, useState } from "react";

import { Box, useMediaQuery } from "@chakra-ui/react";
import Banner from "./BannerSection/Banner";
import MidSection from "./PococaremidSection/MidSection";
import { useNavigate, useParams } from "react-router-dom";
import styleHome from "./Landing.module.css";
import Layout from "./Layout/Layout";
import { useSelector } from "react-redux";
import FixedButton from "./FixedButton/FixedButton";
import { DownloadApp } from "./DownloadApp/DownloadApp";
// import B2CRegistrationForm from "./B2CSubscription/B2CRegistrationForm";
// import { subscriptionFeatures } from "../../FeatureConfig/subscription.feat";
// import Banner from "../../../Components/BannerSection/Banner";

const Landing: React.FC = () => {
  // const [b2cRegisterStatus, setB2CRegisterStatus] = useState(false);
  const { companyName } = useParams();
  const params = companyName ? companyName : "";
  const [isAtTop, setIsAtTop] = useState(true);
  const secondSectionRef = useRef<HTMLDivElement | null>(null);
  const [isLessThan540] = useMediaQuery("(min-width: 540px)");

  //eslint-disable-next-line
  const [secondSectionHeight, setSecondSectionHeight] = useState(0);
  const beneficiaryData = useSelector((state: any) => state.beneficiary);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // const onClickRegister = () => {
  //   setB2CRegisterStatus(true);
  //   navigate("/buysubscription");
  // };

  const scrollToSecondSection = () => {
    if (secondSectionRef.current) {
      // console.log("checck",secondSectionRef.current.offsetTop);
      window.scrollTo(0, secondSectionRef.current.offsetTop);
    }
  };

  useEffect(() => {
    if (beneficiaryData.length) {

      // alert("from landing page-1")
      return navigate("/dashboard");
    }
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsAtTop(scrollY === 0);
    };

    const calculateSecondSectionHeight = () => {
      if (secondSectionRef.current) {
        setSecondSectionHeight(secondSectionRef.current.clientHeight);
      }
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", calculateSecondSectionHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", calculateSecondSectionHeight);
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout companyName={params}>
        <Box className={styleHome.wrapper}>
          <Box className={styleHome.container}>
            <Box>
              <Banner
                // params={companyName}
                params={params}
              />
            </Box>
            <Box>
              <DownloadApp />
            </Box>
            <Box>
              <MidSection secondSectionRef={secondSectionRef} />
            </Box>
          </Box>
        </Box>
        <Box
          position="fixed"
          bottom={isLessThan540 ? "1rem" : ".3rem"}
          left={isLessThan540 ? "50%" : "43%"}
          display="flex"
          justifyContent="flex-end"
          zIndex={1000}
        >
          {isAtTop && (
            <FixedButton
              direction={isAtTop ? "dn" : "up"}
              onClick={isAtTop ? scrollToSecondSection : scrollToTop}
            />
          )}
        </Box>
        {/* <Box
          borderRadius={"8px"}
          position={"fixed"}
          bottom={"30px"}
          right={"40px"}
        >
          {subscriptionFeatures.subscription && (
            <Button
              color="#FFF"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="36px" // or "200%"
              letterSpacing="1.08px"
              height="36px"
              bg={"#01A7B5"}
              _hover={{ bg: "#e961ab" }} // Hover color
              width="full"
              onClick={onClickRegister}
            >
              Register Now
            </Button>
          )}
        </Box> */}
        {/* {b2cRegisterStatus && <B2CRegistrationForm />} */}
      </Layout>
    </>
  );
};

export default Landing;

// Enable feature...