import { Box, Center, Flex, Heading, Image, Text, useColorMode } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { paymentValidation } from "./action";
import { gsap } from "gsap";
import Navbar from "../../GlobalComponents/Navbar/Navbar";


export const PaymentLinkSuccess = () => {
    const urlSearchString = window.location.search;
    const searchParams = new URLSearchParams(urlSearchString);
    const getValues = (key: string) => searchParams.get(key);
    const { colorMode } = useColorMode();


    const payment_link_id = getValues("razorpay_payment_link_id");
    const payment_id = getValues("razorpay_payment_id");
    const payment_link_reference_id = getValues("razorpay_payment_link_reference_id");
    const payment_link_status = getValues("razorpay_payment_link_status");
    const signature = getValues("razorpay_signature");

    const [paymentData, setPaymentData] = useState<any>(null);
    // const [isConfettiActive, setIsConfettiActive] = useState(false);
    // const { width, height } = useWindowSize();

    const successMessageRef = useRef<HTMLDivElement>(null);
    const textItemsRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    useEffect(() => {
        (async () => {
            try {

                let payload = { payment_id, payment_link_id, payment_link_reference_id, payment_link_status, signature };
                const res = await paymentValidation(payload);
                if (res) {
                    // setIsConfettiActive(true);
                    setPaymentData(res.data);
                }


                // GSAP animation for success message
                console.log('successMessageRef:', successMessageRef.current);
                console.log('textItemsRef:', textItemsRef);
                console.log('imageRef:', imageRef)
                if (successMessageRef.current) {
                    gsap.fromTo(successMessageRef.current, { opacity: 0, y: -100 }, { opacity: 1, y: 0, duration: 2.5, ease: "bounce.out", yoyo: true });
                }
                if (textItemsRef.current) {
                    gsap.fromTo(textItemsRef.current.children, 
                        { opacity: 0, y: -20 }, 
                        { opacity: 1, y: 0, duration: 1, stagger: 0.3 }
                    );
                }
                if (imageRef.current) {
                    gsap.to(imageRef.current, { opacity: 0, repeat: -1, yoyo: true, duration: 0.6, ease: "power1.inOut" });
                }
            } catch (e) { }
        })();
        //eslint-disable-next-line
    }, [successMessageRef,textItemsRef,imageRef]);

    return (
        <>
        <Navbar/>
            {paymentData ? <Box minH={"100vh"} display={"flex"} flexDirection={"column"} gap={"1rem"} alignItems={"center"} justifyContent={"center"} bg={colorMode === 'dark' ? 'gray.800' : 'white'} p={4}>
                {/* {isConfettiActive && <Confetti width={width} height={height} numberOfPieces={300} />} */}
                <Flex justifyContent="center" alignItems="center">
                    <Image ref={imageRef} src="/Images/payment_icon.svg" />
                </Flex>
                <Box width={{ base: "100%", lg: "30%" }}>

                    <Heading ref={successMessageRef} as="h2" textAlign="center" color={"#01A601"} mt={4} mb={2}>
                        Payment Successful
                    </Heading>
                    <Flex mt={"2rem"} mb={"1rem"} justify={"space-between"} ref={textItemsRef}>

                        <Box  >
                            <Text mb={4}>
                                Transaction ID</Text>
                            <Text mb={4}>
                                Amount</Text>
                            <Text mb={4}>
                                Service</Text>
                        </Box>
                        <Box >
                            <Text mb={4}>
                                {payment_id}</Text>
                            <Text mb={4}>
                                {paymentData?.amount / 100}</Text>
                            <Text mb={4}>
                                {paymentData?.description}</Text>
                        </Box>
                    </Flex>

                </Box>

            </Box> : <Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} h={"100vh"}>
                    <Center >
                        <Heading as="h2" textAlign="center" color={"red"} mt={4} mb={2}>
                            Payment Failed
                        </Heading>

                    </Center>
                </Box>
            </Box>}
        </>
    );
};
