import React, { useEffect, useState } from 'react'
import Navbar from '../../GlobalComponents/Navbar/Navbar'
import { Box, ListItem, Skeleton, UnorderedList } from '@chakra-ui/react'
import profileStyle from "./Profile.module.css"
import { Link } from "react-scroll";
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleBenDetails } from './Action/getSingleBen';
// import { jsPDF } from 'jspdf';
// import imageCompression from "browser-image-compression";
import * as htmlToImage from 'html-to-image';

import ProfileBasicDetail from './Components/ProfileBasicDetail';
import ProfileAddressDetails from './Components/ProfileAddressDetails';
import ProfileEmergencyContactDetails from './Components/ProfileEmergencyContactDetails';
import ProfileInsurance from './Components/ProfileInsurance';
import ProfilePreferredHospital from './Components/ProfilePreferredHospital';
import ProfileMedicalDetails from './Components/ProfileMedicalDetails';
import { BenDataProps } from '../../Helper/BenInterface';
import { getMedicalInfoAction } from '../BeneficiaryDetails/Actions/beneficiaryDetails.action';

const Profile: React.FC = () => {
    const { profileId } = useParams();
    const navigate = useNavigate();
    const [benData, setBentData] = useState<BenDataProps | null>(null);
    const [isLoading, setLoading] = useState(false)
    const [active, setActive] = useState("basics");

    const captureScreenshot = async () => {
        const profileContainer = document.querySelector(".download") as HTMLElement;

        if (profileContainer) {
            try {
                setLoading(true);
                const dataUrl = await htmlToImage.toSvg(profileContainer, { quality: 0.000001 });

                const img = new Image();
                img.src = dataUrl;

                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = benData?.personalInfo?.fullName + ".svg";
                link.click();

                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const getBeneficiaryData = async () => {
            const data = await getSingleBenDetails(profileId || "6516d50caadd2fce45374fbd");
            const medicalData = await getMedicalInfoAction(profileId || '6516d50caadd2fce45374fbd');
            const fullDetails = { ...data.data, ...medicalData }

            if (data?.data === null) {
                return navigate("/");//need to check weather it is req?
            } else {
                console.log()
                setBentData(fullDetails);
            }
        };
        getBeneficiaryData();
    }, [navigate, profileId]);

    return (
        <>
            <Navbar />


            <Box className={profileStyle.profile_wrapper}>

                <Box className={profileStyle.profile_sub}>
                    {/* //left side */}
                    <Box className={profileStyle.profile_left}>
                        <Box className={profileStyle.profile_left_container}>
                            <UnorderedList
                                className={profileStyle.left_list}
                                listStyleType={"none"}
                                // cursor={"pointer"}
                                spacing={"1rem"}
                            >
                                <ListItem>
                                    <Link
                                        spy={true}
                                        smooth={true}
                                        to="basicInfo"
                                        className={active === "basics" ? profileStyle.active : ""}
                                        onClick={() => setActive("basics")}
                                    >
                                        Basic Info
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        onClick={() => setActive("address")}
                                        className={active === "address" ? profileStyle.active : ""}
                                        spy={true}
                                        smooth={true}
                                        to="addressInfo"
                                    >
                                        Address
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        onClick={() => setActive("emergency")}
                                        className={
                                            active === "emergency" ? profileStyle.active : ""
                                        }
                                        spy={true}
                                        smooth={true}
                                        to="emergencyInfo"
                                    >
                                        Emergency Contacts
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        onClick={() => setActive("medical")}
                                        className={active === "medical" ? profileStyle.active : ""}
                                        spy={true}
                                        smooth={true}
                                        to="medicalInfo"
                                        offset={-10}
                                    >
                                        Medical Profile
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        onClick={() => setActive("insurance")}
                                        className={
                                            active === "insurance" ? profileStyle.active : ""
                                        }
                                        spy={true}
                                        smooth={true}
                                        to="insuranceInfo"
                                        offset={-200}
                                    // onSetActive={check}
                                    >
                                        Insurance Info
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        onClick={() => setActive("hospital")}
                                        className={active === "hospital" ? profileStyle.active : ""}
                                        spy={true}
                                        smooth={true}
                                        to="hospitalInfo"
                                        offset={-100}

                                    // onSetActive={check}
                                    >
                                        Preferred Hospital
                                    </Link>
                                </ListItem>
                            </UnorderedList>
                        </Box>
                    </Box>
                    {/* rightSideFullDetails */}

                    <Box className={`${profileStyle.profile_right} download`}>
                        {benData ? (
                            <Box id="basicInfo" className={profileStyle.benAddressDetails}>
                                <ProfileBasicDetail data={benData} downloadProfilePdf={captureScreenshot} isLoading={isLoading} />
                            </Box>
                        ) : (
                            <Box className={profileStyle.benAddressDetails} height={"100%"}>
                                <Skeleton height={"550px"} borderRadius={"20px"} />
                            </Box>
                        )}
                        {benData ? (
                            <Box id="addressInfo" className={profileStyle.benAddressDetails}>
                                <ProfileAddressDetails data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box
                                id="emergencyInfo"
                                className={profileStyle.benAddressDetails}
                            >
                                <ProfileEmergencyContactDetails data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box id="medicalInfo" className={profileStyle.benAddressDetails}>
                                <ProfileMedicalDetails data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box
                                id="insuranceInfo"
                                className={profileStyle.benAddressDetails}
                            >
                                <ProfileInsurance data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}

                        {benData ? (
                            <Box id="hospitalInfo" className={profileStyle.benAddressDetails}>
                                <ProfilePreferredHospital data={benData} />
                            </Box>
                        ) : (
                            ""
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Profile
