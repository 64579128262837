import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import BenDetailsStyle from './BeneficiaryDetails.module.css';
import { RxPerson } from 'react-icons/rx';
import { IoLocationOutline } from 'react-icons/io5';
import { AiOutlineContacts } from 'react-icons/ai';
import { RiProfileLine } from 'react-icons/ri';
import { MdOutlineHealthAndSafety, MdOutlineDone } from 'react-icons/md';
import { RiHospitalLine } from 'react-icons/ri';
import { getSingleBeneficiaryData } from './Actions/beneficiaryDetails.action'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Show,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { BasicInfo } from './components/BasicInfo/BasicInfo';
// import { Address } from './components/Address/Address';
import { EmergencyContact } from './components/EmergencyContact/EmergencyContact';
import { PreferredHospital } from './components/PreferredHospital/PreferredHospital';
import Navbar from '../../GlobalComponents/Navbar/Navbar';
import MedicalProfile from './components/MedicalProfile/MedicalProfile';
import InsuranceInfo from './components/InsuranceInfo/InsuranceInfo';
import { NewAddress } from './components/NewAddress/NewAddress';

const BeneficiaryDetails: React.FC = () => {
  const navigate = useNavigate();
  const { benId = "", step } = useParams();
  const [data, setData] = useState<any>();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)')
  const [currentStep, setCurrentStep] = useState<number>(parseInt(step || "1")); // Parse step as an integer
  const steps: Record<number, React.ComponentType<any>> = {
    1: BasicInfo,
    2: NewAddress,
    3: EmergencyContact,
    4: MedicalProfile,
    5: InsuranceInfo,
    6: PreferredHospital,
  };
  const Step = steps[currentStep];

  const changeStep = (step: number) => {
    setCurrentStep((prevStep) => prevStep + step);
  };

  const handleProfilePreviewClick = () => {
    navigate(`/profile/view/${benId}`);
  };

  const getBeneficiary = async () => {
    try {
      let benData = await getSingleBeneficiaryData(benId);
      setData(benData);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getBeneficiary();

  }, [benId]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar />
      <div className={BenDetailsStyle.main}>
        <Flex
        flexDirection={isLargerThan900?"row":"column"}
          justifyContent={"space-between"}
          width={"80%"}
          margin={"auto"}
        >
          <Box>
            <Heading as={"h2"} size="lg">
              Beneficiary Details
            </Heading>
            <br />

            <Box>
            <Text fontWeight={"500"} color={"#3B5753"} width={"80%"}>
              {/* Please share all information that will aid researchers and
                physicians in developing new techniques to anticipate or
                diagnose illness. */}

              Please share all necessary details to help the <b>"Pococare Heartbeat Team"</b> assist you in a medical emergency
            </Text>
          </Box>
            
          </Box>
          <Flex flexDirection='column' justifyContent={'space-evenly'} padding={'5px'}>
            <Text fontSize='lg' textAlign='right'>
              <b>
                {data?.personalInfo?.fullName}
              </b>
            </Text>
            <Show breakpoint="(min-width: 900px)">
              <Box>
                <Button
                  onClick={handleProfilePreviewClick}
                  borderRadius={"50px"}
                  color={"#008470"}
                  bg="white"
                  border={"1px solid #008470"}
                  variant="solid"
                >
                  Profile Preview
                </Button>
              </Box>
            </Show>
          </Flex>
        </Flex>


        <div className={BenDetailsStyle.benDetails}>
          <div className={BenDetailsStyle.left}>
            <HStack cursor={"pointer"} onClick={() => setCurrentStep(1)}>
              {data?.personalInfo?.gender ? (
                <MdOutlineDone className={BenDetailsStyle.completed} />
              ) : (
                <RxPerson
                  className={
                    currentStep === 1
                      ? BenDetailsStyle.iconBgGreen
                      : BenDetailsStyle.iconBgGray
                  }
                />
              )}
              <Text
                className={
                  currentStep === 1
                    ? BenDetailsStyle.textGreen
                    : BenDetailsStyle.textBlack
                }
              >
                Basic Information
              </Text>
            </HStack>
            <Divider
              borderColor={currentStep > 1 ? "#008470" : "#eff3f3"}
              marginLeft={"20px"}
              height={"35px"}
              orientation="vertical"
            />
            <HStack cursor={"pointer"} onClick={() => setCurrentStep(2)}>
              {data?.address?.length ? (
                <MdOutlineDone className={BenDetailsStyle.completed} />
              ) : (
                <IoLocationOutline
                  className={
                    currentStep === 2
                      ? BenDetailsStyle.iconBgGreen
                      : BenDetailsStyle.iconBgGray
                  }
                />
              )}
              <Text
                className={
                  currentStep === 2
                    ? BenDetailsStyle.textGreen
                    : BenDetailsStyle.textBlack
                }
              >
                Address
              </Text>
            </HStack>
            <Divider
              borderColor={currentStep > 2 ? "#008470" : "#eff3f3"}
              marginLeft={"20px"}
              height={"35px"}
              orientation="vertical"
            />
            <HStack cursor={"pointer"} onClick={() => setCurrentStep(3)}>
              {data?.emergencyContacts?.length ? (
                <MdOutlineDone className={BenDetailsStyle.completed} />
              ) : (
                <AiOutlineContacts
                  className={
                    currentStep === 3
                      ? BenDetailsStyle.iconBgGreen
                      : BenDetailsStyle.iconBgGray
                  }
                />
              )}
              <Text
                className={
                  currentStep === 3
                    ? BenDetailsStyle.textGreen
                    : BenDetailsStyle.textBlack
                }
              >
                Emergency Contact
              </Text>
            </HStack>
            <Divider
              borderColor={currentStep > 3 ? "#008470" : "#eff3f3"}
              marginLeft={"20px"}
              height={"35px"}
              orientation="vertical"
            />

            <HStack cursor={"pointer"} onClick={() => setCurrentStep(4)}>
              {(data?.medicalInfo?.allergiesToMedications?.length || data?.medicalInfo?.chronicConditions?.length || data?.medicalInfo?.currentMedications?.length || data?.medicalInfo?.generalInfo?.bloodGroup || data?.medicalInfo?.generalInfo?.differentlyAble || data?.medicalInfo?.generalInfo?.mobilityStatus || data?.medicalInfo?.pastMedicalRecords?.length || data?.medicalInfo?.recentHospitalizations?.length) ? (
                <MdOutlineDone className={BenDetailsStyle.completed} />
              ) : (
                <RiProfileLine
                  className={
                    currentStep === 4
                      ? BenDetailsStyle.iconBgGreen
                      : BenDetailsStyle.iconBgGray
                  }
                />
              )}
              <Text
                className={
                  currentStep === 4
                    ? BenDetailsStyle.textGreen
                    : BenDetailsStyle.textBlack
                }
              >
                Medical Profile
              </Text>
            </HStack>
            <Divider
              borderColor={currentStep > 4 ? "#008470" : "#eff3f3"}
              marginLeft={"20px"}
              height={"35px"}
              orientation="vertical"
            />
            <HStack cursor={"pointer"} onClick={() => setCurrentStep(5)}>
              {data?.insurancePolicies?.length ? (
                <MdOutlineDone className={BenDetailsStyle.completed} />
              ) : (
                <MdOutlineHealthAndSafety
                  className={
                    currentStep === 5
                      ? BenDetailsStyle.iconBgGreen
                      : BenDetailsStyle.iconBgGray
                  }
                />
              )}
              <Text
                className={
                  currentStep === 5
                    ? BenDetailsStyle.textGreen
                    : BenDetailsStyle.textBlack
                }
              >
                Insurance Information
              </Text>
            </HStack>
            <Divider
              borderColor={currentStep > 5 ? "#008470" : "#eff3f3"}
              marginLeft={"20px"}
              height={"35px"}
              orientation="vertical"
            />
            <HStack cursor={"pointer"} onClick={() => setCurrentStep(6)}>
              {data?.address?.length > 0 && ((data?.address[0]?.preferredHospital?.length > 0 && data?.address[0]?.preferredHospital[0]?.hospitalName) ||( data?.address[1]?.preferredHospital?.length > 0 && data?.address[1]?.preferredHospital[0]?.hospitalName) || (data?.address[2]?.preferredHospital?.length > 0 && data?.address[2]?.preferredHospital[0]?.hospitalName)) ? (
                <MdOutlineDone className={BenDetailsStyle.completed} />
              ) : (
                <RiHospitalLine
                  className={
                    currentStep === 6
                      ? BenDetailsStyle.iconBgGreen
                      : BenDetailsStyle.iconBgGray
                  }
                />
              )}
              <Text
                className={
                  currentStep === 6
                    ? BenDetailsStyle.textGreen
                    : BenDetailsStyle.textBlack
                }
              >
                Preferred Hospital
              </Text>
            </HStack>
          </div>
          <div className={BenDetailsStyle.right}>
            {data ? (
              <Step
                changeStep={changeStep}
                data={data}
                setData={setData}
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryDetails;
