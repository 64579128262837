import { findBenIdAlreadyMapped, raiseEmergencyWithCompanyId } from "../../../http";

export const raiseEmergencyWithCompanyIdAction = async (companyId:string,mobile:any,lat:string|null,lng:string|null) => {    
    try {
       let data = await raiseEmergencyWithCompanyId(companyId,mobile,lat,lng)
       return data;
    } catch (error:any) {
      throw error;      
    }
  };

  export const checkBenIdIsMapped=async (duplicateId:string)=>{

    try {
    const res=await findBenIdAlreadyMapped(duplicateId);
    return res;      
    } catch (error) {
      console.log('error:from map ben', error);
      throw error;
      
    }

  }
  