import { Box, Text, useToast } from '@chakra-ui/react';
import styles from '../Orders.module.css';
import apiConfig from '../../../apiConfig/apiConfig';

export const Receipt = ({ pocoOrderId }: { pocoOrderId: string }) => {
  const toast = useToast(); // Initialize the toast hook
  const baseUri = apiConfig.api.baseURL || "https://v3.apis.pococare.com";
  const handleClick = () => {
    try {
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = `${baseUri}/payment/api/v1/invoice/generate-invoice/${pocoOrderId}`;
      link.setAttribute('download', 'receipt.pdf'); // Optional: Set the download attribute for naming the file
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to start download
      document.body.removeChild(link); // Clean up after download

      // Notify the user that the download has started using a toast
      toast({
        title: 'Open receipt in PDF',
        description: 'Your receipt PDF is downloaded.',
        status: 'info',
        duration: 3000, // Show toast for 3 seconds
        isClosable: true, // Allow user to close the toast
      });
    } catch (error) {
      console.error('Error downloading the receipt:', error);
      toast({
        title: 'Error',
        description: 'Error downloading the receipt right now, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className={styles['reciept']}>
      <Text className={styles['reciept-text']} onClick={handleClick}>Open receipt in PDF</Text>
    </Box>
  );
};
