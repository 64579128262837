import React, { useEffect } from "react";

// import Navbar from "../../Components/Navbar/Navbar";
import Dashboardstyle from "./DashBoard.module.css";
import {
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CardComponent from "./Component/CardCompo/Card";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useGeolocated } from "react-geolocated";
import { BenDataProps } from "../../Helper/BenInterface";
import AddBeneficiaryModal from "./Component/AlertDialog/AddBeneficiaryModal";
import { AiFillPlusCircle } from "react-icons/ai";
import Navbar from "../../GlobalComponents/Navbar/Navbar";
import { getAllBen } from "../Landing/Otp/Action/getAllBen";
import { useNavigate } from "react-router-dom";
import {
  checkConsentStatusAction,
  saveConsentInfoAction,
} from "./Action/callAction";
import { generateSaSToken } from "../../Helper/sasToken";
// import { getAllBen } from '../Landing/Otp/Action/getAllBen';
const DashBoard: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCreateModal, setShowModal] = useState(false);
  const beneficiaryData = useSelector((state: any) => state.beneficiary);
  const subscriberData: BenDataProps = useSelector((state: any) => state.subscriber); // eslint-disable-line
  const userData: any = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // eslint-disable-line
  const [isSmallerThan375] = useMediaQuery("(max-width: 375px)");
  const [installPrompt, setInstallPrompt] = useState(null);

  localStorage.setItem("pwaBen", JSON.stringify(beneficiaryData));

  const dispatch = useDispatch();
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: Infinity,
  });
  // console.log("coords:", coords);
  const navigateTOPage = (benData: any) => {
    let hasIncompleteBeneficiary = false;
    for (let i = 0; i < benData?.data?.length; i++) {
      const mobile = benData.data[i]?.personalInfo?.mobile;
      const mobileNumber = benData.data[i]?.personalInfo?.mobile?.number;
      const mobileCountryCode =
        benData.data[i]?.personalInfo?.mobile?.countryCode;
      console.log("data", benData.data[i]);
      if (
        mobile === null ||
        !mobileNumber ||
        !mobile ||
        !mobileCountryCode ||
        (isNaN(mobileNumber) && isNaN(mobileCountryCode)) ||
        benData.data[i]?.address?.length === 0
      ) {
        hasIncompleteBeneficiary = true;
        break;
      }
    }
    console.log("hasIncompleteBeneficiary:", hasIncompleteBeneficiary);
    createBenPayload(benData);
    if (hasIncompleteBeneficiary && userData.userRole !== "BENEFICIARY") {
      setTimeout(() => {
        // alert("check-222dash")
        setLoading(false);
        return navigate("/addBenMinDetails");
      }, 200);
    } else {
      setLoading(true);
    }
  };
  const createBenPayload = (benPayload: any) => {
    const benUpdate: any = [];
    benPayload.data.forEach((benData: any, i: any) => {
      ////matchfieldpending////////////////
      console.log("benData:", benData);
      const benDataForLocalStorage: any = {};
      benDataForLocalStorage["fullName"] =
        benData?.personalInfo?.fullName || "N/A";
      benDataForLocalStorage["relationshipToSubscriber"] =
        benData?.personalInfo?.relationshipToSubscriber || "N/A";
      benDataForLocalStorage["city"] = benData?.address[0]?.city || "";
      benDataForLocalStorage["addressId"] = benData.address[0]?._id || "";
      benDataForLocalStorage["pinCode"] = benData?.address[0]?.pinCode || "";
      benDataForLocalStorage["mobile"] = {
        number: benData?.personalInfo?.mobile?.number || "",
        countryCode: benData?.personalInfo?.mobile?.countryCode || "",
      };
      benDataForLocalStorage["index"] = i;
      benDataForLocalStorage["_id"] = benData._id;

      benUpdate.push(benDataForLocalStorage);
    });
    localStorage.setItem("beneficiariesData", JSON.stringify(benUpdate));
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setInstallPrompt(e);
      onOpen();
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []); // eslint-disable-line

  // const getSubsCriberName = () => {
  //   let self = beneficiaryData.find((benData: any) => {
  //     return (
  //       benData?.personalInfo?.relationshipToSubscriber?.toLowerCase() ===
  //       "self"
  //     );
  //   });

  //   return self?.personalInfo?.fullName || "User";
  // };

  useEffect(() => {
    // console.log('beneficiaryData:', beneficiaryData);
    async function fetchData() {
      try {
        const data = await getAllBen(beneficiaryData[0].subscriberId, dispatch);
        // console.log('data:', data)
        navigateTOPage(data);
      } catch (error) {
        // Handle any errors that occur during the async operation
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []); // eslint-disable-line
  const handleInstallClick = () => {
    if (installPrompt) {
      // @ts-ignore
      installPrompt.prompt();
      // @ts-ignore
      installPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User declined the install prompt");
        }
        setInstallPrompt(null);
        onClose();
      });
    }
  };

  const addBen = () => {
    async function fetchData() {
      try {
        const data = await getAllBen(beneficiaryData[0].subscriberId, dispatch);
        // console.log('data:', data)
        navigateTOPage(data);
      } catch (error) {
        // Handle any errors that occur during the async operation
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  };

  useEffect(() => {
    async function saveConsent() {
      try {
        let user = localStorage.getItem("userData");
        if (user) {
          const parseData = JSON.parse(user);
          const checkConsent = await checkConsentStatusAction(parseData.userId);
          if (checkConsent.length === 0) {
            const consentPayload = {
              benId: parseData.userId,
              consentStatus: true,
            };
            await saveConsentInfoAction(consentPayload);
          } else {
            console.log("It seems user has already given the consent.");
          }
        } else {
          console.log(
            "No user data found consent management Dashboard"
          );
        }
      } catch (error) {
        console.error("Error while saving consent.");
      }
    }

    saveConsent();
  }, []);

  useEffect(() => {
    generateSaSToken();
  }, [])

  return (
    <>
      {
        loading ? (
          <>
            <Navbar />
            <div className={Dashboardstyle.main}>
              <div className={Dashboardstyle.header}>
                <img src="/Images/checklist.jpg" alt="checklist" />
                <div>
                  <h3>
                    Welcome{" "}
                    <span style={{ color: "blue" }}>
                      {subscriberData?.personalInfo?.fullName + ","}
                      {/* {getSubsCriberName() + ","} */}
                    </span>
                  </h3>
                  <p>
                    You are now ready to receive medical emergency assistance,
                    anytime, anywhere for you and your family members
                  </p>
                </div>
              </div>
              <div className={Dashboardstyle.benHeading}>
                <Heading as={"h2"} size="lg">
                  My Beneficiaries
                </Heading>
                {(userData?.addBeneficiaryAllowed === undefined ||
                  userData?.addBeneficiaryAllowed === true) && (!userData?.numberOfBenAllowed || (userData?.numberOfBenAllowed && userData?.numberOfBenAllowed > beneficiaryData.length)) && (
                    <Show breakpoint="(min-width: 1001px)">
                      <Menu>
                        <MenuButton
                          px={4}
                          py={2}
                          bg={"#008470"}
                          transition="all 0.2s"
                          borderRadius="3xl"
                          borderWidth="1px"
                          _expanded={{ bg: "#008470" }}
                        >
                          <p style={{ color: "white" }}>
                            Add Beneficiary <ChevronDownIcon />
                          </p>
                        </MenuButton>
                        <MenuList bg={"#008470"}>
                          <MenuItem
                            onClick={() => {
                              setShowModal(true);
                            }}
                            color={"white"}
                            bg={"#008470"}
                          >
                            Create New
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Show>
                  )}
                {(userData?.addBeneficiaryAllowed === undefined ||
                  userData?.addBeneficiaryAllowed === true) && (!userData?.numberOfBenAllowed || (userData?.numberOfBenAllowed && userData?.numberOfBenAllowed > beneficiaryData.length)) && (
                    <Show breakpoint="(max-width: 1000px)">
                      <AiFillPlusCircle
                        className={Dashboardstyle.floatingbutton}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      />
                    </Show>
                  )}
              </div>
              <div className={Dashboardstyle.benificiaryList}>
                {/* map thr ben card data */}
                {beneficiaryData.map((el: any, i: number) => {
                  return (
                    <CardComponent
                      key={i}
                      personalInfo={el.personalInfo}
                      progress={el.progress}
                      id={el._id}
                      isSmallerThan375={isSmallerThan375}
                      lat={coords?.latitude ? String(coords.latitude) : null}
                      lng={coords?.longitude ? String(coords.longitude) : null}
                    />
                  );
                })}
              </div>
            </div>
            {/* floating action button */}
            <div className={Dashboardstyle.floatingBtnDiv}></div>

            {/* Install PWA Modal */}
            <>
              <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Pococare Emergency</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text fontWeight="bold" mb="1rem">
                      Get immediate medical help with just one click by
                      installing our Emergency App.
                    </Text>
                  </ModalBody>

                  <ModalFooter>
                    {/* @ts-ignore */}
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                    {/* @ts-ignore */}
                    <Button onClick={handleInstallClick} colorScheme="green">
                      Install
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>

            {/* emergency alert */}
          </>
        ) : (
          <>Something bad happened</>
        )
        // <Loader />
      }

      <AddBeneficiaryModal
        addBen={addBen}
        isOpen={showCreateModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};
export default DashBoard;
