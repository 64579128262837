export function formatTimestamp(createdAt:string) {
    const date = new Date(createdAt);

    // Get day, month, and year
    const day = date.getDate();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

    // Determine the suffix for the day
    const daySuffix = (d:any) => {
        if (d > 3 && d < 21) return 'th'; // Handle 11th, 12th, 13th, etc.
        switch (d % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

    // Construct the formatted string
    const formattedDate = `${day}${daySuffix(day)}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
}