import * as beneficiarySlice from "../../../Redux/Beneficiary-Slice/Beneficiary-slice";
// import * as beneficiarySlice from "../../../../Redux/Beneficiary-Slice/Beneficiary-slice";

import { addBeneficiaries } from "../../../http";

export interface CreatePayload {
  data: benData[];
  subscriberId: {
    ID: string;
  };
}
export interface CreatePayloadB2C {
  data: benDataB2C[];
  subscriberId: {
    ID: string;
  };
}

export interface benDataB2C {
    userId?: string | null;
    relationshipToSubscriber: string;
    fullName: string;
    mobile: {
      countryCode: number;
      number: number;
    } | null;
    city: string;
    pinCode: number;
  }

export interface benData {
  relationshipToSubscriber: string;
  fullName: string;
  mobile: {
    countryCode: number;
    number: number;
  } | null;
  city: string;
  pinCode: number;
}

export const createBeneficiaries = async (
  payload: CreatePayload,
  dispatch: any
) => {
  try {
    const { data } = await addBeneficiaries(payload);
    dispatch(beneficiarySlice.addBeneficiary(data.data[0].response.data));
    if (data.data.length) {
      return data;
    }
  } catch (e: any) {
    throw e;
    // return e.response?.data
  }
};
export const createBeneficiariesForB2c = async (
  payload: CreatePayloadB2C,
  dispatch: any
) => {
  try {
    const { data } = await addBeneficiaries(payload);
    dispatch(beneficiarySlice.addBeneficiary(data.data[0].response.data));
    if (data.data.length) {
      return data;
    }
  } catch (e: any) {
    throw e;
    // return e.response?.data
  }
};
