import { Box, Button, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import style from "../Styles/paymentSuccess.module.css";
import { useNavigate } from "react-router-dom";
import { formatUnixTimeStamp } from "../Common/common";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriber } from "../../../../Otp/Action/getSubscriber";
import { saveSbsSubscriberData } from "../../../../../../Redux/B2CSubscriber-Slice/Subscriber-sbs-slice";

interface PaymentSuccessInterface {
  orderId: string | undefined;
  amount: string | undefined;
  timeStamp: string | undefined;
  benCount: number;
}

export const PaymentSuccess: React.FC<PaymentSuccessInterface> = ({
  amount,
  timeStamp,
  orderId,
  benCount,
}) => {
  // Fetch the subs data..
  const subscriberData = useSelector((state: any) => state.subscriber);
  const userData = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery("(max-width:480px)");

  const handleReturn = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // Fetch the data and update the redux
    // GET THE USER AND SUBS DATA HERE .
    let subscriptionData = await getSubscriber(subscriberData.subscriberId);
    dispatch(saveSbsSubscriberData(subscriptionData));
    if (userData.numberOfBenAllowed > 1) {
      return navigate("/addBeneficiaries")
    } else {
      return navigate("/dashboard");
    }

  };

  return (
    <Box className={style.main}>
      <Box className={style.child}>
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Image className={style.img} src="/Images/paymentSuccessfull.png" />
          <Text className={style.successText}>Payment Successful</Text>
        </Flex>
        <Flex className={style.paymentDetails}>
          <Box>
            <Text fontSize={isMobile ? "10px" : "14px"}>Order ID :</Text>
            <Text fontSize={isMobile ? "10px" : "14px"}>Amount :</Text>
            <Text fontSize={isMobile ? "10px" : "14px"}>Timestamp :</Text>
          </Box>
          <Box>
            <Text fontSize={isMobile ? "10px" : "14px"}>{orderId || "Null"}</Text>
            <Text fontSize={isMobile ? "10px" : "14px"}>{`Rs. ${Number(amount) / 100}/-`}</Text>
            <Text fontSize={isMobile ? "10px" : "14px"}>{formatUnixTimeStamp(Number(timeStamp))}</Text>
          </Box>
        </Flex>
        <Button className={style.btn} colorScheme="cyan" onClick={handleReturn}>
          <Text className={style.btnTxt}>Back To Home</Text>
        </Button>
      </Box>
    </Box>
  );
};
