export function createBen(data: any) {
  return {
    data: [
      {
        userId: data.sub,
        relationshipToSubscriber: "Self",
        fullName: data.fullname,
        mobile: { countryCode: 91, number: data.mobile },
        city: "NA",
        pinCode: 90001,
        email:data?.email,
      },
    ],
    subscriberId: {
      ID: data.subscriberId,
    },
  };
}

export function userRegPayload(userData: any) {
  return {
    name: userData.name,
    email: userData.email,
    mobileNo: {
      countryCode: 91,
      number: +userData.mobile,
    },
    numberOfBenAllowed: 1,
  };
}
export function userRegOtpPayload(userData: any, otp: any) {
  return {
    countryCode: "91",
    number: userData.mobile,
    email: userData.email,
    otp: otp,
  };
}
