import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text } from "@chakra-ui/react";
import { findBenIdAlreadyMappedByBenId, mapBenIdToDuplicateId } from "../../http";
import { ShowAlert } from "../../GlobalComponents/ShowAlert/ShowAlert";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

const BenQrMapping = () => {
  const navigate = useNavigate();
  // Fetch subscriber data from Redux
  const subscriber = useSelector((state: any) => state.subscriber);
  const beneficiaries = useSelector((state: any) => state.beneficiary);
  const getBenQrId = sessionStorage.getItem("benMapping");

  const [isModalVisible, setIsModalVisible] = useState(false); // State to manage modal visibility

  const mapBenId = async (qrId: string) => {
    const benPayload = {
      benId: subscriber._id,
      companyName: subscriber?.company?.companyName || "N/A",
      activeFlag: true,
    };
    try {
      const res = await mapBenIdToDuplicateId({ benPayload, qrId });
      console.log('res:', res);
      
      if (res?.data) {
        sessionStorage.removeItem("benMapping");
        setIsModalVisible(true);

      } 
      // ShowAlert("warning", "Some technical issue in mapping QR. Try again later.");
      return res.data;
      
    } catch (error: any) {
      ShowAlert("warning", error?.response?.data?.message || "Some technical issue in mapping QR. Try again later.");
      navigate("/dashboard");
    }
  };


  useQuery({queryKey:["mapQr"],queryFn:async()=>{
    if(getBenQrId){
      const qrId = JSON.parse(getBenQrId).slice(4);
      return await mapBenId(qrId);      
    }else{
      ShowAlert("warning", "Scan Qr and Check Again");
      navigate("/dashboard")
    }
  }});
  const findBenALreadyMapped = async () => {
    try {
      const res = await findBenIdAlreadyMappedByBenId(subscriber._id);
      if (res.data && !getBenQrId) {  // If already mapped and QR ID is not in session storage
        navigate("/dashboard");
      }
      return res.data;
    } catch (error: any) {
      if(error.response.data.statusCode===404){
        return;
      }

      ShowAlert("warning", error?.response?.data?.message || "Some technical issue in mapping QR. Try again later.");
    }
  };
  useQuery({
    queryKey: ['findQrMapped', subscriber._id],
    queryFn: findBenALreadyMapped,
    enabled: !!subscriber._id,
    retry: 2, // Retry twice in case of failure
  });
  const handleOk = () => {
    setIsModalVisible(false);
    navigate("/dashboard"); // Redirect to dashboard after closing the modal
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };



  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(155deg, #FCD0E8, #BEE9ED)",
        fontFamily: "Poppins, sans-serif",
        padding: "20px",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: "30px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "60%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          fontFamily: "Poppins, sans-serif",
          alignItems: "center",
        }}
      >
        <h1>QR Mapping</h1>
        {subscriber ? (
          <div>
            <h3>Welcome, {subscriber.personalInfo.fullName}</h3>
          </div>
        ) : (
          <p>Loading subscriber details...</p>
        )}

        <div>
          {beneficiaries?.length ? (
            <ul>
              {beneficiaries.map((ben: any) =>
                ben.personalInfo.relationshipToSubscriber.toLowerCase() === "self" ? (
                  <li
                    key={ben._id}
                    style={{
                      marginBottom: "10px",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "10px",
                      border: "1px solid #FCD0E8",
                      display: "flex",
                      alignItems: "center", // Align items vertically centered
                      padding: "10px", // Add padding for better spacing
                      borderRadius: "5px",
                      gap: "1rem", // Gap between items
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center", // Align checkbox and text
                        gap: "10px", // Add gap between checkbox and name
                      }}
                    >
                      <input
                        type="radio"
                        name="beneficiary"
                        value={ben.id}                     
                        checked={true} 
                        disabled={true}
                      />
                      <span>
                        {ben.personalInfo.fullName} ({ben.activeFlag ? "Mapped" : "Unmapped"})
                      </span>
                    </label>
                  </li>
                ) : null
              )}
            </ul>
          ) : (
            <p>No beneficiaries found.</p>
          )}
        </div>

        {/* Removed manual Map button, as mapping happens automatically now */}
      </div>
      <Modal isOpen={isModalVisible} onClose={handleCancel} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Beneficiary Mapped Successfully</ModalHeader>
          <ModalBody>
            <Text>
              QR has been successfully mapped to the beneficiary! You can now scan it to raise emergencies.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleOk}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BenQrMapping;
